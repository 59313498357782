export const LOGIN_DEFAULT = "LOGIN_DEFAULT";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_STARTED = "LOGIN_STARTED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_STARTED = "VERIFY_STARTED";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_FAILURE = "VERIFY_FAILURE";

export const LOGOUT_STARTED = "LOGOUT_STARTED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const GET_PENDING_USERS_STARTED = "GET_PENDING_USERS_STARTED";
export const GET_PENDING_USERS_SUCCESS = "GET_PENDING_USERS_SUCCESS";
export const GET_PENDING_USERS_FAILURE = "GET_PENDING_USERS_FAILURE";

export const GET_APPROVED_USERS_STARTED = "GET_APPROVED_USERS_STARTED";
export const GET_APPROVED_USERS_SUCCESS = "GET_APPROVED_USERS_SUCCESS";
export const GET_APPROVED_USERS_FAILURE = "GET_APPROVED_USERS_FAILURE";

export const GET_KNOW_YOUR_CUSTOMER_STARTED = "GET_KNOW_YOUR_CUSTOMER_STARTED";
export const GET_KNOW_YOUR_CUSTOMER_SUCCESS = "GET_KNOW_YOUR_CUSTOMER_SUCCESS";
export const GET_KNOW_YOUR_CUSTOMER_FAILURE = "GET_KNOW_YOUR_CUSTOMER_FAILURE";

export const CHANGE_STATUS_KYC_FIELD_STARTED =
  "CHANGE_STATUS_KYC_FIELD_STARTED";
export const CHANGE_STATUS_KYC_FIELD_SUCCESS =
  "CHANGE_STATUS_KYC_FIELD_SUCCESS";
export const CHANGE_STATUS_KYC_FIELD_FAILURE =
  "CHANGE_STATUS_KYC_FIELD_FAILURE";

export const CONFIRM_KYC_STARTED = "CONFIRM_KYC_STARTED";
export const CONFIRM_KYC_SUCCESS = "CONFIRM_KYC_SUCCESS";
export const CONFIRM_KYC_FAILURE = "CONFIRM_KYC_FAILURE";

export const MAKE_STARTER_USER_STARTED = "MAKE_STARTER_USER_STARTED";
export const MAKE_STARTER_USER_SUCCESS = "MAKE_STARTER_USER_SUCCESS";
export const MAKE_STARTER_USER_FAILURE = "MAKE_STARTER_USER_FAILURE";

export const MAKE_PREMIUM_USER_STARTED = "MAKE_PREMIUM_USER_STARTED";
export const MAKE_PREMIUM_USER_SUCCESS = "MAKE_PREMIUM_USER_SUCCESS";
export const MAKE_PREMIUM_USER_FAILURE = "MAKE_PREMIUM_USER_FAILURE";

export const GET_ALL_USERS_STARTED = "GET_ALL_USERS_STARTED";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE";

export const GET_STARTER_USERS_STARTED = "GET_STARTER_USERS_STARTED";
export const GET_STARTER_USERS_SUCCESS = "GET_STARTER_USERS_SUCCESS";
export const GET_STARTER_USERS_FAILURE = "GET_STARTER_USERS_FAILURE";

export const GET_PREMIUM_USERS_STARTED = "GET_PREMIUM_USERS_STARTED";
export const GET_PREMIUM_USERS_SUCCESS = "GET_PREMIUM_USERS_SUCCESS";
export const GET_PREMIUM_USERS_FAILURE = "GET_PREMIUM_USERS_FAILURE";

export const GET_AGENTS_LIST_STARTED = "GET_AGENTS_LIST_STARTED";
export const GET_AGENTS_LIST_SUCCESS = "GET_AGENTS_LIST_SUCCESS";
export const GET_AGENTS_LIST_FAILURE = "GET_AGENTS_LIST_FAILURE";

export const GET_AGENTS_BALANCES_STARTED = "GET_AGENTS_BALANCES_STARTED";
export const GET_AGENTS_BALANCES_SUCCESS = "GET_AGENTS_BALANCES_SUCCESS";
export const GET_AGENTS_BALANCES_FAILURE = "GET_AGENTS_BALANCES_FAILURE";


export const SET_AGENT = "SET_AGENT";

export const GET_USERS_STARTED = "GET_USERS_STARTED";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const GET_ADMIN_STARTED = "GET_ADMIN_STARTED";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const GET_ADMIN_FAILURE = "GET_ADMIN_FAILURE";

export const GET_ADMINS_LIST_STARTED = "GET_ADMINS_LIST_STARTED";
export const GET_ADMINS_LIST_SUCCESS = "GET_ADMINS_LIST_SUCCESS";
export const GET_ADMINS_LIST_FAILURE = "GET_ADMINS_LIST_FAILURE";
export const SET_Admin = "SET_Admin";

export const GET_CURRENCIES_STARTED = "GET_CURRENCIES_STARTED";
export const GET_CURRENCIES_SUCCESS = "GET_CURRENCIES_SUCCESS";
export const GET_CURRENCIES_FAILURE = "GET_CURRENCIES_FAILURE";

export const SET_CURRENCY = "SET_CURRENCY";

export const TOP_UP_STARTED = "TOP_UP_STARTED";
export const TOP_UP_SUCCESS = "TOP_UP_SUCCESS";
export const TOP_UP_FAILURE = "TOP_UP_FAILURE";

export const CREATE_USER_DEFAULT_WALLET_STARTED =
  "CREATE_USER_DEFAULT_WALLET_STARTED";
export const CREATE_USER_DEFAULT_WALLET_SUCCESS =
  "CREATE_USER_DEFAULT_WALLET_SUCCESS";
export const CREATE_USER_DEFAULT_WALLET_FAILURE =
  "CREATE_USER_DEFAULT_WALLET_FAILURE";

export const EDIT_AGENT_DEFAULT_WALLET_STARTED =
  "EDIT_AGENT_DEFAULT_WALLET_STARTED";
export const EDIT_AGENT_DEFAULT_WALLET_SUCCESS =
  "EDIT_AGENT_DEFAULT_WALLET_SUCCESS";
export const EDIT_AGENT_DEFAULT_WALLET_FAILURE =
  "EDIT_AGENT_DEFAULT_WALLET_FAILURE";

export const DELETE_DEFAULT_WALLET_STARTED = "DELETE_DEFAULT_WALLET_STARTED";
export const DELETE_DEFAULT_WALLET_SUCCESS = "DELETE_DEFAULT_WALLET_SUCCESS";
export const DELETE_DEFAULT_WALLET_FAILURE = "DELETE_DEFAULT_WALLET_FAILURE";

export const UPDATE_AGENT_STARTED = "UPDATE_AGENT_STARTED";
export const UPDATE_AGENT_SUCCESS = "UPDATE_AGENT_SUCCESS";
export const UPDATE_AGENT_FAILURE = "UPDATE_AGENT_FAILURE";

export const GET_AGENT_TRANSACTIONS_STARTED = "GET_AGENT_TRANSACTIONS_STARTED";
export const GET_AGENT_TRANSACTIONS_SUCCESS = "GET_AGENT_TRANSACTIONS_SUCCESS";
export const GET_AGENT_TRANSACTIONS_FAILURE = "GET_AGENT_TRANSACTIONS_FAILURE";

export const GET_USER_DEFAULTS_STARTED = "GET_USER_DEFAULTS_STARTED";
export const GET_USER_DEFAULTS_SUCCESS = "GET_USER_DEFAULTS_SUCCESS";
export const GET_USER_DEFAULTS_FAILURE = "GET_USER_DEFAULTS_FAILURE";

export const GET_USER_FEES_STARTED = "GET_USER_FEES_STARTED";
export const GET_USER_FEES_SUCCESS = "GET_USER_FEES_SUCCESS";
export const GET_USER_FEES_FAILURE = "GET_USER_FEES_FAILURE";

export const GET_ADMIN_DEFAULTS_STARTED = "GET_ADMIN_DEFAULTS_STARTED";
export const GET_ADMIN_DEFAULTS_SUCCESS = "GET_ADMIN_DEFAULTS_SUCCESS";
export const GET_ADMIN_DEFAULTS_FAILURE = "GET_ADMIN_DEFAULTS_FAILURE";

export const GET_ADMIN_FEES_STARTED = "GET_ADMIN_FEES_STARTED";
export const GET_ADMIN_FEES_SUCCESS = "GET_ADMIN_FEES_SUCCESS";
export const GET_ADMIN_FEES_FAILURE = "GET_ADMIN_FEES_FAILURE";

export const CREATE_USER_FEES_WALLET_STARTED =
  "CREATE_USER_FEES_WALLET_STARTED";
export const CREATE_USER_FEES_WALLET_SUCCESS =
  "CREATE_USER_FEES_WALLET_SUCCESS";
export const CREATE_USER_FEES_WALLET_FAILURE =
  "CREATE_USER_FEES_WALLET_FAILURE";

export const DOWNLOAD_FILE_STARTED = "DOWNLOAD_FILE_STARTED";
export const DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS";
export const DOWNLOAD_FILE_FAILURE = "DOWNLOAD_FILE_FAILURE";

export const UPLOAD_FILE_STARTED = "UPLOAD_FILE_STARTED";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";

export const GET_USER_DOCUMENTS_STARTED = "GET_USER_DOCUMENTS_STARTED";
export const GET_USER_DOCUMENTS_SUCCESS = "GET_USER_DOCUMENTS_SUCCESS";
export const GET_USER_DOCUMENTS_FAILURE = "GET_USER_DOCUMENTS_FAILURE";

export const GET_TOP_UP_LIST_STARTED = "GET_TOP_UP_LIST_STARTED";
export const GET_TOP_UP_LIST_SUCCESS = "GET_TOP_UP_LIST_SUCCESS";
export const GET_TOP_UP_LIST_FAILURE = "GET_TOP_UP_LIST_FAILURE";

export const GET_TOP_UP_CARD_LIST_STARTED = "GET_TOP_UP_CARD_LIST_STARTED";
export const GET_TOP_UP_CARD_LIST_SUCCESS = "GET_TOP_UP_CARD_LIST_SUCCESS";
export const GET_TOP_UP_CARD_LIST_FAILURE = "GET_TOP_UP_CARD_LIST_FAILURE";

export const GET_AGENT_TOP_UP_LIST_STARTED = "GET_AGENT_TOP_UP_LIST_STARTED";
export const GET_AGENT_TOP_UP_LIST_SUCCESS = "GET_AGENT_TOP_UP_LIST_SUCCESS";
export const GET_AGENT_TOP_UP_LIST_FAILURE = "GET_AGENT_TOP_UP_LIST_FAILURE";

export const GET_TOP_UP_COUNTRIES_STARTED = "GET_TOP_UP_COUNTRIES_STARTED";
export const GET_TOP_UP_COUNTRIES_SUCCESS = "GET_TOP_UP_COUNTRIES_SUCCESS";
export const GET_TOP_UP_COUNTRIES_FAILURE = "GET_TOP_UP_COUNTRIES_FAILURE";

export const GET_TOP_UP_BANKS_STARTED = "GET_TOP_UP_BANKS_STARTED";
export const GET_TOP_UP_BANKS_SUCCESS = "GET_TOP_UP_BANKS_SUCCESS";
export const GET_TOP_UP_BANKS_FAILURE = "GET_TOP_UP_BANKS_FAILURE";

export const CREATE_TOP_UP_IBAN_STARTED = "CREATE_TOP_UP_IBAN_STARTED";
export const CREATE_TOP_UP_IBAN_SUCCESS = "CREATE_TOP_UP_IBAN_SUCCESS";
export const CREATE_TOP_UP_IBAN_FAILURE = "CREATE_TOP_UP_IBAN_FAILURE";

export const GET_TOP_UP_IBAN_LIST_STARTED = "GET_TOP_UP_IBAN_LIST_STARTED";
export const GET_TOP_UP_IBAN_LIST_SUCCESS = "GET_TOP_UP_IBAN_LIST_SUCCESS";
export const GET_TOP_UP_IBAN_LIST_FAILURE = "GET_TOP_UP_IBAN_LIST_FAILURE";

export const DELETE_TOP_UP_IBAN_STARTED = "DELETE_TOP_UP_IBAN_STARTED";
export const DELETE_TOP_UP_IBAN_SUCCESS = "DELETE_TOP_UP_IBAN_SUCCESS";
export const DELETE_TOP_UP_IBAN_FAILURE = "DELETE_TOP_UP_IBAN_FAILURE";

export const GET_EXCHANGE_RATE_LIST_STARTED = "GET_EXCHANGE_RATE_LIST_STARTED";
export const GET_EXCHANGE_RATE_LIST_SUCCESS = "GET_EXCHANGE_RATE_LIST_SUCCESS";
export const GET_EXCHANGE_RATE_LIST_FAILURE = "GET_EXCHANGE_RATE_LIST_FAILURE";

export const CREATE_EXCHANGE_RATE_STARTED = "CREATE_EXCHANGE_RATE_STARTED";
export const CREATE_EXCHANGE_RATE_SUCCESS = "CREATE_EXCHANGE_RATE_SUCCESS";
export const CREATE_EXCHANGE_RATE_FAILURE = "CREATE_EXCHANGE_RATE_FAILURE";

export const DELETE_EXCHANGE_RATE_STARTED = "DELETE_EXCHANGE_RATE_STARTED";
export const DELETE_EXCHANGE_RATE_SUCCESS = "DELETE_EXCHANGE_RATE_SUCCESS";
export const DELETE_EXCHANGE_RATE_FAILURE = "DELETE_EXCHANGE_RATE_FAILURE";

export const GET_VIRTUAL_CARDS_LIST_STARTED = "GET_VIRTUAL_CARDS_LIST_STARTED";
export const GET_VIRTUAL_CARDS_LIST_SUCCESS = "GET_VIRTUAL_CARDS_LIST_SUCCESS";
export const GET_VIRTUAL_CARDS_LIST_FAILURE = "GET_VIRTUAL_CARDS_LIST_FAILURE";

export const CREATE_CREDIT_CARD_STARTED = "CREATE_CREDIT_CARD_STARTED";
export const CREATE_CREDIT_CARD_SUCCESS = "CREATE_CREDIT_CARD_SUCCESS";
export const CREATE_CREDIT_CARD_FAILURE = "CREATE_CREDIT_CARD_FAILURE";

export const CREATE_PHYSICAL_CARD_STARTED = "CREATE_PHYSICAL_CARD_STARTED";
export const CREATE_PHYSICAL_CARD_SUCCESS = "CREATE_PHYSICAL_CARD_SUCCESS";
export const CREATE_PHYSICAL_CARD_FAILURE = "CREATE_PHYSICAL_CARD_FAILURE";

export const TOP_UP_CARD_STARTED = "TOP_UP_CARD_STARTED";
export const TOP_UP_CARD_SUCCESS = "TOP_UP_CARD_SUCCESS";
export const TOP_UP_CARD_FAILURE = "TOP_UP_CARD_FAILURE";

export const SEND_EMAIL_STARTED = "SEND_EMAIL_STARTED";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILURE = "SEND_EMAIL_FAILURE";

export const GET_PHYSICAL_CARDS_LIST_STARTED =
  "GET_PHYSICAL_CARDS_LIST_STARTED";
export const GET_PHYSICAL_CARDS_LIST_SUCCESS =
  "GET_PHYSICAL_CARDS_LIST_SUCCESS";
export const GET_PHYSICAL_CARDS_LIST_FAILURE =
  "GET_PHYSICAL_CARDS_LIST_FAILURE";

export const GET_USERS_LOG_STARTED = "GET_USERS_LOG_STARTED";
export const GET_USERS_LOG_SUCCESS = "GET_USERS_LOG_SUCCESS";
export const GET_USERS_LOG_FAILURE = "GET_USERS_LOG_FAILURE";

export const GET_PEP_LOGS_STARTED = "GET_PEP_LOGS_STARTED";
export const GET_PEP_LOGS_SUCCESS = "GET_PEP_LOGS_SUCCESS";
export const GET_PEP_LOGS_FAILURE = "GET_PEP_LOGS_FAILURE";

export const GET_ADMIN_ACTIVITY_LOGS_STARTED =
  "GET_ADMIN_ACTIVITY_LOGS_STARTED";
export const GET_ADMIN_ACTIVITY_LOGS_SUCCESS =
  "GET_ADMIN_ACTIVITY_LOGS_SUCCESS";
export const GET_ADMIN_ACTIVITY_LOGS_FAILURE =
  "GET_ADMIN_ACTIVITY_LOGS_FAILURE";

export const GET_DASHBOARD_ACTIVITY_LOGS_STARTED =
  "GET_DASHBOARD_ACTIVITY_LOGS_STARTED";
export const GET_DASHBOARD_ACTIVITY_LOGS_SUCCESS =
  "GET_DASHBOARD_ACTIVITY_LOGS_SUCCESS";
export const GET_DASHBOARD_ACTIVITY_LOGS_FAILURE =
  "GET_DASHBOARD_ACTIVITY_LOGS_FAILURE";

export const EDIT_WALLET_MINIMUM_BALANCE_STARTED =
  "EDIT_WALLET_MINIMUM_BALANCE_STARTED";
export const EDIT_WALLET_MINIMUM_BALANCE_SUCCESS =
  "EDIT_WALLET_MINIMUM_BALANCE_SUCCESS";
export const EDIT_WALLET_MINIMUM_BALANCE_FAILURE =
  "EDIT_WALLET_MINIMUM_BALANCE_FAILURE";

export const CALCULATE_FEE_STARTED = "CALCULATE_FEE_STARTED";
export const CALCULATE_FEE_SUCCESS = "CALCULATE_FEE_SUCCESS";
export const CALCULATE_FEE_FAILURE = "CALCULATE_FEE_FAILURE";

export const FORGOT_PASSWORD_FIRST_STEP_STARTED =
  "FORGOT_PASSWORD_FIRST_STEP_STARTED";
export const FORGOT_PASSWORD_FIRST_STEP_SUCCESS =
  "FORGOT_PASSWORD_FIRST_STEP_SUCCESS";
export const FORGOT_PASSWORD_FIRST_STEP_FAILURE =
  "FORGOT_PASSWORD_FIRST_STEP_FAILURE";

export const FORGOT_PASSWORD_SECOND_STEP_STARTED =
  "FORGOT_PASSWORD_SECOND_STEP_STARTED";
export const FORGOT_PASSWORD_SECOND_STEP_SUCCESS =
  "FORGOT_PASSWORD_SECOND_STEP_SUCCESS";
export const FORGOT_PASSWORD_SECOND_STEP_FAILURE =
  "FORGOT_PASSWORD_SECOND_STEP_FAILURE";

export const FORGOT_PASSWORD_THIRD_STEP_STARTED =
  "FORGOT_PASSWORD_THIRD_STEP_STARTED";
export const FORGOT_PASSWORD_THIRD_STEP_SUCCESS =
  "FORGOT_PASSWORD_THIRD_STEP_SUCCESS";
export const FORGOT_PASSWORD_THIRD_STEP_FAILURE =
  "FORGOT_PASSWORD_THIRD_STEP_FAILURE";


export const GET_ADMIN_VIRTUAL_CARDS_STARTED = "GET_ADMIN_VIRTUAL_CARDS_STARTED";
export const GET_ADMIN_VIRTUAL_CARDS_SUCCESS = "GET_ADMIN_VIRTUAL_CARDS_SUCCESS";
export const GET_ADMIN_VIRTUAL_CARDS_FAILURE = "GET_ADMIN_VIRTUAL_CARDS_FAILURE";
